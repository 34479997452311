import axios from "@/config/http";
// OBS BrowserJS SDK，不支持npm引入,需手动引入项目,相对路径自己配置
const ObsClient = require("../../public/esdk-obs-browserjs-without-polyfill.3.22.3.min.js");
/**
 *
 * @param url 项目接口地址
 * @name 获取obs临时密钥
 * @returns 密钥
 * 需要安装插件 axios
 */
export async function getAkAndSk(url) {
  let obj = {};
  await axios
    .get(url)
    .then((res) => {
      obj = res.data;
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
  return obj;
}
/**
 *
 * @name obs文件上传
 * @param file 上传的文件，必填
 * @param url 项目接口地址
 * @param bucketName 桶名
 * @param sk 临时密钥对象
 * @returns 文件线上访问地址
 * 需要安装插件 moment.js  js-md5  华为云BrowserJS的SDK
 */
export async function initObsClient(file, bucketName, sk) {
  //时间格式化插件 
  let moment = require("moment");
  //md5加密插件
  const md5 = require("js-md5");
  const time = moment().format("YYYY/MM/DD") + "/";
  const fileObject= JSON.stringify(file)
  const md5File = md5(fileObject);

  const fileName =
    time + md5File + "." + file.name.substring(file.name.lastIndexOf(".") + 1);
    console.log('fileName',fileName)
  try {
    //获取临时密钥
    const client = new ObsClient({
      security_token: sk.securitytoken, //临时securityToken
      access_key_id: sk.access, //ak+ 
      secret_access_key: sk.secret, //sk
      server: "obs.cn-east-3.myhuaweicloud.com", //server地址
    });
    //发送请求
    return new Promise((reslove) => {
      client.putObject(
        {
          Bucket: bucketName, //桶名称
          Key: fileName, //上传文件名
          SourceFile: file, //文件
        },
        //上传回调
        function (err, result) {
          if (err) {
            return reslove(false);
          } else {
            // console.log("Status-->" + result.CommonMsg.Status);
            if (result.CommonMsg.Status === 200) {
              console.log("文件名");
              console.log(fileName);
              reslove({
                fileAddress:
                  "https://" +
                  bucketName +
                  ".obs.cn-east-3.myhuaweicloud.com/" +
                  fileName,
                fileName: fileName,
              });
            }
          }
        }
      );
    });
  } catch (e) {
    console.log(e);
    return false;
  }
}
/**
 *
 * @name obs私密文件下载及回显
 * @param fileName 文件名
 * @param url 项目接口地址
 * @param bucketName 桶名
 * @returns 文件线上访问地址
 * 需要安装插件 moment.js  js-md5  华为云BrowserJS的SDK
 */
export async function GetSecretObsClient(fileName, bucketName, sk) {
  try {
    console.log("obs文件名");
    console.log(fileName)
    //获取临时密钥
    let client = new ObsClient({
      security_token: sk.securitytoken, //临时securityToken
      access_key_id: sk.access, //ak+
      secret_access_key: sk.secret, //sk
      server: "obs.cn-east-3.myhuaweicloud.com", //server地址
    });
    //发送请求
    return new Promise((reslove) => {
      client.getObject(
        {
          Bucket: bucketName,
          Key: fileName,
          SaveByType: "arraybuffer",
        },
        function (err, result) {
          if (err) {
            console.error("资源读取Error" + err);
          } else {
            if (result.CommonMsg.Status < 300 && result.InterfaceResult) {
              const arrayBuff = result.InterfaceResult.Content;
              const blob = new Blob([arrayBuff]);
              const imgUrl = window.URL.createObjectURL(blob);
              reslove({
                fileAddress: imgUrl,
                fileName: fileName,
              });
            }
            // else{
            //   console.log("这里？？？")
            //   return false;
            // }
          }
        }
      );
    });
  } catch (e) {
    console.log("报错", e);
    return false;
  }
}
